import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'

// Context
import { store } from '../../store'

import style from './index.module.css'

const TableOfContents = ({
	location,
}: {
	location: {
		pathname: string
	}
}) => {
	const globalState = React.useContext(store)
	const { dispatch } = globalState

	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
			allMarkdownRemark(
				sort: { order: ASC, fields: [frontmatter___chapter] }
				limit: 1000
			) {
				edges {
					node {
						frontmatter {
							date(formatString: "MMMM DD, YYYY")
							chapter
							path
							title
						}
					}
				}
			}
		}
	`)

	const Chapters = ({
		location,
	}: {
		location: {
			pathname: string
		}
	}) => data.allMarkdownRemark.edges.map(
		(
			edge: {
				node: {
					frontmatter: {
						chapter: string
						path: string
						title: string
					}
					id: string
				}
			}
		) => {
			// Create regex to check for pathname with optional trailing '/'
			const re = new RegExp(edge.node.frontmatter.path + '/?$', 'g');
			const pathMatched = location.pathname.match(re)

			return (
				<li
					className={ pathMatched ? style.NavActive : '' }
					key={ edge.node.frontmatter.chapter }
				>
					<Link
						onClick={ () => dispatch({ type: 'CLOSE_NAVIGATION' }) }
						to={ edge.node.frontmatter.path }
					>
						<span className={ style.ChapterNumber }>
							{
								`${edge.node.frontmatter.chapter.toString().padStart(2, '0')}.`
							}
						</span>
						<span className={ style.ChapterTitle }>
							<u>{
								edge.node.frontmatter.title
							}</u>
						</span>
					</Link>
				</li>
			)
		}
	)

	return (
		<nav className={ style.TOC }>
			<ul className={ style.ChapterNav }>
				{ Chapters({ location }) }
			</ul>
		</nav>
	)
}

export default TableOfContents
