import React from 'react'

// Style
import style from './index.module.css'

/**
 * Available icons
 * More can be found at https://github.com/danklammer/bytesize-icons
 * =================================================================================================
 */
const icons = (name: string) => {
	switch (name) {
		case 'home':
			return <path d="M12 20 L12 30 4 30 4 12 16 2 28 12 28 30 20 30 20 20 Z" />
		case 'book':
			return (
				<>
					<path
						d="M16.5,12.4c0-4.4,3.6-8,8-8s8,3.6,8,8v15.2c0-4.4-3.6-8-8-8s-8,3.6-8,8c0-4.4-3.6-8-8-8s-8,3.6-8,8V12.4
						c0-4.4,3.6-8,8-8S16.5,8,16.5,12.4z"/>
					<line x1="16.5" x2="16.5" y1="12.4" y2="27.6"/>
					<rect height="32" style={ { opacity: 0, fill: 'none' } } width="32" x="0.5" />
				</>
			)
		case 'close':
			return <path d="M2 30 L30 2 M30 30 L2 2" />
		case 'focus':
			return (
				<g>
					<circle cx="16" cy="16" r="15" />
					<path d="M16,9.438A6.563,6.563,0,0,0,9.438,16V29.508" />
					<path d="M9.438,16A6.563,6.563,0,0,0,16,22.563H29.485" />
					<path d="M16,22.563A6.563,6.563,0,0,0,22.563,16V2.509" />
					<path d="M2.509,9.438H16A6.563,6.563,0,0,1,22.563,16" />
				</g>
			)
		case 'bookmark':
			return <path d="M6 2 L26 2 26 30 16 20 6 30 Z" />
		case 'print':
			return <path d="M7 25 L2 25 2 9 30 9 30 25 25 25 M7 19 L7 30 25 30 25 19 Z M25 9 L25 2 7 2 7 9 M22 14 L25 14" />
		case 'share':
			return <path d="M2 16 L30 2 16 30 12 20 Z M30 2 L12 20" />
		default:
			return (
				<g>
					<path d="M20 24 L12 16 2 26 2 2 30 2 30 24 M16 20 L22 14 30 22 30 30 2 30 2 24" />
					<circle cx="10" cy="9" r="3" />
				</g>
			)
	}
}

type IconProps = {
	name: string
	size?: number
}

/**
 * Exports selected icon w/ inline SVG.
 */
const Icon: React.FC<IconProps> = ({ name, size }) => {
	const icon = icons(name)
	return (
		<svg
			className={ style.Icon }
			fill="none"
			height={ size }
			preserveAspectRatio="xMidYMid meet"
			stroke="currentcolor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="1"
			vectorEffect="non-scaling-stroke"
			viewBox={ `0 0 ${size} ${size}` }
			width={ size }
			xmlns="http://www.w3.org/2000/svg"
		>
			{ icon }
		</svg>
	)
}

Icon.defaultProps = {
	size: 32,
}

export default Icon
