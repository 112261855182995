import React from 'react'
import { Link } from 'gatsby'
import useOnclickOutside from 'react-cool-onclickoutside'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

// Context
import { store } from '../../store'

// Components
import Icon from '../../components/Icon'
import TableOfContents from '../../components/TableOfContents'

// Style
import style from './index.module.css'

type LayoutProps = {
	children: React.ReactNode
	location: {
		href: string
		pathname: string
	}
}

/**
 * Wrapper component for entire application. Every page is loaded into this one vie children.
 */
const Layout: React.FC<LayoutProps> = ({
	children,
	location,
}) => {
	const globalState = React.useContext(store)
	const { dispatch, state } = globalState
	const menuRef = React.useRef<HTMLDivElement>(null)
	const toggleRef = React.useRef<HTMLButtonElement>(null)

	const chapterSlug = location.pathname.replace(/\/chapter\/([^\/]+)\/?$/g, '$1')

	useOnclickOutside([menuRef, toggleRef], () => {
		dispatch({ type: 'CLOSE_NAVIGATION' })
	});

	return (
		<>
			<button
				className={ style.Toggle }
				onClick={
					() => {
						dispatch({ type: state.isTOCActive ? 'CLOSE_NAVIGATION' : 'OPEN_NAVIGATION' })
						trackCustomEvent({
							category: "Menu Toggle",
							action: "Click",
						})
					}
				}
				ref={ toggleRef }
			>
				<Icon name="book" />
			</button>
			{
				state.isTOCActive ? (
					<nav className={ style.Navigation }>
						<div className={ style.SiteNav } ref={ menuRef }>
							<button
								className={ style.Close }
								onClick={
									() => {
										dispatch({ type: 'CLOSE_NAVIGATION' })
										trackCustomEvent({
											category: "Menu Close",
											action: "Click",
										})
									}
								}
							>
								<Icon name="close" />
							</button>
							<ul className={ style.MainNav }>
								<li className={ location.pathname === '/' ? style.NavActive : '' }>
									<Link onClick={ () => dispatch({ type: 'CLOSE_NAVIGATION' }) } to="/"><u>Home</u></Link>
								</li>
								<li className={ location.pathname === '/about' ? style.NavActive : '' }>
									<Link onClick={ () => dispatch({ type: 'CLOSE_NAVIGATION' }) } to="/about"><u>About</u></Link>
								</li>
								<li className={ location.pathname === '/contact' ? style.NavActive : '' }>
									<Link onClick={ () => dispatch({ type: 'CLOSE_NAVIGATION' }) } to="/contact"><u>Contact</u></Link>
								</li>
								<li>
									<a
										href="https://confirmsubscription.com/h/d/80D85D6A4A8CF261"
										onClick={
											() => {
												dispatch({ type: 'CLOSE_NAVIGATION' })
												trackCustomEvent({
													category: "Navigation",
													action: "Click",
													label: "Subscribe",
												})
											}
										}
										target="_blank"
									>
										Subscribe for Updates
									</a>
								</li>
							</ul>
							<TableOfContents location={ location } />
						</div>
					</nav>
				) : null
			}
			<div className={ style.Layout }>
				<main
					className={ [style.Body, chapterSlug].join(' ') }
				>
					{ children }
				</main>
			</div>
		</>
	)
}

export default Layout
