import React from 'react'

// Components
const Layout = require('./src/components/Layout/').default

// Context
import { StateProvider } from './src/store'

export const onClientEntry = () => {
	// IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
	if (!(`IntersectionObserver` in window)) {
		require('intersection-observer')
	}
}

export const wrapPageElement = ({ element, props }) => (
	// props provide same data to Layout as Page element will get
	// including location, data, etc - you don't need to pass it
	<Layout { ...props }>{ element }</Layout>
)

export const wrapRootElement = ({ element }) => (
	<StateProvider>
		{ element }
	</StateProvider>
)
