// store.js
import React from 'react'

type AppState = {
	isFocused: boolean
	isTOCActive: boolean
}

const initialState: AppState = {
	isFocused: false,
	isTOCActive: false
}

type Action =
	| { type: 'TOGGLE_NAVIGATION' }
	| { type: 'CLOSE_NAVIGATION' }
	| { type: 'OPEN_NAVIGATION' }

const initialDispatch: React.Dispatch<Action> = () => {}
const store = React.createContext({
	state: initialState,
	dispatch: initialDispatch
})

const StateProvider = ({
	children
}: {
	children: React.ReactNode
}) => {
	const [state, dispatch] = React.useReducer(
		(state: AppState, action: Action): AppState => {
			switch (action.type) {
				case "TOGGLE_NAVIGATION": {
					return {
						...state,
						isTOCActive: !state.isTOCActive
					}
				}
				case 'CLOSE_NAVIGATION': {
					return {
						...state,
						isTOCActive: false
					}
				}
				case 'OPEN_NAVIGATION': {
					return {
						...state,
						isTOCActive: true
					}
				}
				default:
					throw new Error()
			}
		},
		initialState
	)

	return <store.Provider value={ { state, dispatch } }>{ children }</store.Provider>
}

export { store, StateProvider }
